import Address from "../components/core/forms/contact/Address";
import { Container } from "react-bootstrap";
import { IMPRESSUM } from "../components/core/nav/NavigationItems";
import Layout from "../components/core/Layout";
import React from "react";

const ImprintPage = () => (
  <Layout page={IMPRESSUM}>
    <Container>
      <h2 className="text-uppercase h-muted">new.space</h2>
      <h1 className="text-uppercase h bolder">Impressum</h1>
      <h3 className="headline">Angaben gemäß § 5 TMG</h3>
      <Address />
      <h3 className="headline">Aufsichtsrat</h3>
      <p>
        Herr Diethelm Rüthers (Vorsitzender)
        <br />
        Herr Rainer Pillmayer (Stellvertretender Vorsitzender)
        <br />
        Herr Matthias Henning
      </p>
      <h3 className="headline">Vorstand</h3>
      <p>
        Herr Michael Kopeinigg
        <br />
        Herr Nikolaus Kühn
      </p>
      <h3 className="headline">Registereintrag</h3>
      <p>
        Eintragung im Handelsregister.
        <br />
        Registergericht: Kassel
        <br />
        Registernummer: HRB 17228
      </p>
    </Container>
  </Layout>
);

export default ImprintPage;
